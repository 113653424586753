import React from 'react'
import dayjs from 'dayjs'
import { areIntervalsOverlapping } from 'date-fns'
import { timeFormat } from 'utils/format'
import { Button } from 'antd'
import { useTranslation } from 'react-i18next'

const useTimeRangePickerHelper = () => {
  const { t } = useTranslation()

  const isMidnight = (date) => dayjs(date).hour() === 0 && dayjs(date).minute() === 0

  const convertIfStartOfDay = (date) => {
    return isMidnight(date) ? dayjs(date).subtract(1, 'day') : date
  }

  const convertIfEndOfDay = (begin, end) => {
    if (!isMidnight(end)) return end

    return dayjs(begin).add(1, 'day').startOf('day')
  }

  const disableTimeEnd = ({ date, field, form, listPropertyName }) => {
    const begin = form.getFieldValue(listPropertyName)[field.name]?.begin
    if (!begin) {
      return false
    }

    const midnight = isMidnight(date)

    // ms difference bypass
    return !midnight ? begin.millisecond(0) >= date.millisecond(0) : false
  }

  const checkDateValidity = ({ date, field, form, listPropertyName }) => {
    const formValues = form.getFieldsValue()
    const end = formValues[listPropertyName]?.[field.name]?.end

    if (end && date.millisecond(0) >= end.millisecond(0)) {
      formValues[listPropertyName][field.name].end = null
      form.setFieldsValue({ ...formValues, [listPropertyName]: formValues[listPropertyName] })
    }
  }

  const checkIfDateRangesOverlap = ({ values, onHasOverlaps = () => {}, onNoOverlaps = () => {} }) => {
    const intervals = values.map((el) => ({
      start: new Date(el.begin),
      end: new Date(el.end)
    }))
    let interval = 0
    let intervalToCompare = 0
    const overlappingIntervals = []

    if (intervals != null && intervals.length > 1) {
      for (interval = 0; interval < intervals.length - 1; interval += 1) {
        for (
          intervalToCompare = interval + 1;
          intervalToCompare < intervals.length;
          intervalToCompare += 1
        ) {
          if (
            areIntervalsOverlapping(
              intervals[interval],
              intervals[intervalToCompare]
            )
          ) {
            overlappingIntervals.push(timeFormat(intervals[interval].start) + '-' +
            timeFormat(intervals[interval].end), timeFormat(intervals[intervalToCompare].start) + '-' +
            timeFormat(intervals[intervalToCompare].end))
          }
        }
      }
    }

    if (overlappingIntervals.length > 0) {
      const filteredIntervals = Array.from(new Set(overlappingIntervals)).join('\n')
      onHasOverlaps(filteredIntervals)
    } else {
      onNoOverlaps()
    }
  }

  const renderEndOfDayBtn = ({ field, form, listPropertyName }) => {
    const handleSetToEndOfDay = () => {
      const formValues = form.getFieldsValue()
      formValues[listPropertyName][field.name].end = dayjs().add(1, 'day').startOf('day')
      form.setFieldsValue({ ...formValues, [listPropertyName]: formValues[listPropertyName] })
    }
    return <Button size="small" onClick={handleSetToEndOfDay}>{t('general.end-of-day')}</Button>
  }

  return { isMidnight, disableTimeEnd, checkDateValidity, checkIfDateRangesOverlap, convertIfEndOfDay, convertIfStartOfDay, renderEndOfDayBtn }
}

export default useTimeRangePickerHelper
