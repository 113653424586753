import { createSlice } from '@reduxjs/toolkit'
import { estimateApi, predictionReportageApi } from 'services/api'

const initialState = {
  reportageList: {},
  prognosisChartData: [],
  isListLoading: false,
  isChartLoading: false,
  isDownloadReportageFileLoading: false,
  error: null
}

const reportageSlice = createSlice({
  name: 'reportage',
  initialState,
  reducers: {
    getReportageListStart (state) {
      state.error = null
      state.isListLoading = true
    },
    getReportageListSuccess (state, { payload }) {
      state.reportageList = payload
      state.error = null
      state.isListLoading = false
    },
    getReportageListFailed (state, { payload }) {
      state.reportageList = {}
      state.error = payload.error
      state.isListLoading = false
    },
    downloadReportageFileStart (state) {
      state.isDownloadReportageFileLoading = true
    },
    downloadReportageFileSuccess (state) {
      state.isDownloadReportageFileLoading = false
      state.error = null
    },
    downloadReportageFileFailed (state, { payload }) {
      state.isDownloadReportageFileLoading = false
      state.error = payload.error
    },
    getPrognosisChartDataStart (state, { payload }) {
      state.prognosisChartData = []
      state.error = null
      state.isChartLoading = true
    },
    getPrognosisChartDataSuccess (state, { payload }) {
      state.prognosisChartData = payload
      state.error = null
      state.isChartLoading = false
    },
    getPrognosisChartDataFailed (state, { payload }) {
      state.prognosisChartData = []
      state.error = payload.error
      state.isChartLoading = false
    },
    clear (state) {
      state.reportageList = {}
    }
  }
})

const {
  getReportageListStart,
  getReportageListSuccess,
  getReportageListFailed,
  downloadReportageFileSuccess,
  downloadReportageFileFailed,
  getPrognosisChartDataStart,
  getPrognosisChartDataSuccess,
  getPrognosisChartDataFailed,
  clear
} = reportageSlice.actions

const getReportageList = (params) => async dispatch => {
  try {
    dispatch(getReportageListStart())
    const reportageList = await estimateApi.getPaginatedEstimate(params)
    dispatch(getReportageListSuccess(reportageList))
  } catch (error) {
    dispatch(getReportageListFailed({ error }))
  }
}

const downloadReportageFile = (estimateId) => async dispatch => {
  try {
    const reportageFile = await predictionReportageApi.downloadReportage({ estimateId }, { responseType: 'blob' })
    dispatch(downloadReportageFileSuccess())
    return reportageFile
  } catch (error) {
    dispatch(downloadReportageFileFailed({ error }))
    return null
  }
}

const getPrognosisChartData = (estimateId) => async dispatch => {
  try {
    dispatch(getPrognosisChartDataStart())
    const chartData = await predictionReportageApi.getGraphReportage({ estimateId })
    dispatch(getPrognosisChartDataSuccess(chartData))
  } catch (error) {
    dispatch(getPrognosisChartDataFailed({ error }))
  }
}

export {
  getReportageList,
  downloadReportageFile,
  getPrognosisChartData,
  clear
}

export default reportageSlice.reducer
