import { createSlice } from '@reduxjs/toolkit'
import { annualEstimateApi } from 'services/api'
import dayjs from 'dayjs'

const initialState = {
  estimateStartDateFromStore: null,
  prognosisTableSourceFromStore: [],

  annualEstimatePaginatedGroups: {},
  isAnnualEstimatePaginatedGroupsLoading: false,

  annualEstimateAllGroups: [],
  isAllGroupsLoading: false,

  error: null
}

const annualEstimateSlice = createSlice({
  name: 'annualestimate',
  initialState,
  reducers: {
    setEstimateStartDateFromStore (state, { payload }) {
      state.estimateStartDateFromStore = payload

      const tableSource = []
      for (let idx = 0; idx < 12; idx++) {
        tableSource.push({ id: idx, month: dayjs(payload).date(1).add(idx, 'month').format(), daysOffNumber: null })
      }

      state.prognosisTableSourceFromStore = []
      state.prognosisTableSourceFromStore.push(...tableSource)
    },
    setDaysOff (state, { payload }) {
      const { id, value } = payload
      state.prognosisTableSourceFromStore = state.prognosisTableSourceFromStore.map(item => {
        if (item.id === id) {
          return { ...item, daysOffNumber: value === 0 ? 1 : value }
        }
        return item
      })
    },
    setAllAnnualEstimateGroupsLoading (state) {
      state.isAllGroupsLoading = true
    },
    getAllAnnualEstimateGroupsSuccess (state, { payload }) {
      state.annualEstimateAllGroups = payload
      state.error = null
      state.isAllGroupsLoading = false
    },
    getAnnualEstimateGroupsFailed (state, { payload }) {
      state.annualEstimateAllGroups = []
      state.error = payload.error
      state.isAllGroupsLoading = false
    },
    getAnnualEstimatePaginatedGroupsStart (state) {
      state.error = null
      state.isAnnualEstimatePaginatedGroupsLoading = true
    },
    getAnnualEstimatePaginatedGroupsSuccess (state, { payload }) {
      state.annualEstimatePaginatedGroups = payload
      state.error = null
      state.isAnnualEstimatePaginatedGroupsLoading = false
    },
    getAnnualEstimatePaginatedGroupsFailed (state, { payload }) {
      state.annualEstimatePaginatedGroups = []
      state.error = payload.error
      state.isAnnualEstimatePaginatedGroupsLoading = false
    },
    setCreateAnnualEstimateGroupsLoading (state) {
      state.isAllGroupsLoading = true
    },
    getCreateAnnualEstimateGroupsSuccess (state) {
      state.annualEstimateAllGroups = []
      state.error = null
      state.isAllGroupsLoading = false
    },
    getCreateAnnualEstimateGroupsFailed (state, { payload }) {
      state.error = payload.error
      state.isAllGroupsLoading = false
    },
    clear (state) {
      state.estimateStartDateFromStore = null
      state.annualEstimateAllGroups = []
      state.annualEstimatePaginatedGroups = []
      state.error = null
    }
  }
})

const {
  setEstimateStartDateFromStore,
  setDaysOff,
  setAllAnnualEstimateGroupsLoading,
  getAllAnnualEstimateGroupsSuccess,
  getAnnualEstimateGroupsFailed,
  getAnnualEstimatePaginatedGroupsStart,
  getAnnualEstimatePaginatedGroupsSuccess,
  getAnnualEstimatePaginatedGroupsFailed,
  setCreateAnnualEstimateGroupsLoading,
  getCreateAnnualEstimateGroupsSuccess,
  getCreateAnnualEstimateGroupsFailed,
  clear
} = annualEstimateSlice.actions

const handleChangeEstimateStartDate = (value) => async dispatch => {
  const { estimateStartDate } = value
  dispatch(setEstimateStartDateFromStore(estimateStartDate?.format()))
}

const modifyDaysOff = (dto, changedValue) => async dispatch => {
  dispatch(setDaysOff({ id: dto.id, value: changedValue.value }))
}

const getAllAnnualEstimateGroups = () => async dispatch => {
  try {
    dispatch(setAllAnnualEstimateGroupsLoading())
    const allGroups = await annualEstimateApi.getAllAnnualEstimateGroups()
    dispatch(getAllAnnualEstimateGroupsSuccess(allGroups))
  } catch (error) {
    dispatch(getAnnualEstimateGroupsFailed({ error }))
  }
}

const getPaginatedAnnualEstimateGroups = (params) => async dispatch => {
  try {
    dispatch(getAnnualEstimatePaginatedGroupsStart())
    const paginatedGroupList = await annualEstimateApi.getPaginatedGroups(params)
    dispatch(getAnnualEstimatePaginatedGroupsSuccess(paginatedGroupList))
  } catch (error) {
    dispatch(getAnnualEstimatePaginatedGroupsFailed({ error }))
  }
}

const createAnnualGroupEstimate = (createAnnualEstimateGroupDto) => async dispatch => {
  try {
    dispatch(setCreateAnnualEstimateGroupsLoading())
    await annualEstimateApi.createAnnualEstimateGroup({ createAnnualEstimateGroupDto })
    dispatch(getCreateAnnualEstimateGroupsSuccess())
    return { isSuccess: true }
  } catch (error) {
    dispatch(getCreateAnnualEstimateGroupsFailed({ error }))
    return { isSuccess: false }
  }
}

const downloadGroupScheduleMessageXml = (id) => async dispatch => {
  const response = await annualEstimateApi.groupScheduleMessageXml({ id }, { responseType: 'blob' })
  return response
}

export {
  handleChangeEstimateStartDate,
  getAllAnnualEstimateGroups,
  getPaginatedAnnualEstimateGroups,
  createAnnualGroupEstimate,
  downloadGroupScheduleMessageXml,
  modifyDaysOff,
  clear
}

export default annualEstimateSlice.reducer
