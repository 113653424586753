import { stringify } from 'qs'
import { useMemo } from 'react'
import { history } from 'router'
import { useLocation } from 'react-router-dom'
import { unWrapValues } from 'utils/filters'
import { parseParams } from 'utils/url'
import { ListSortDirection } from 'models/enum'

const useTableControl = () => {
  const location = useLocation()

  const params = useMemo(() => {
    const queryParams = parseParams(location.search)
    const { pageNumber, pageSize, orderFieldName, orderDirection, ...filters } = queryParams

    return {
      pagination: {
        pageNumber,
        pageSize: pageSize && [10, 25, 50].includes(Number(pageSize)) ? pageSize : 10
      },
      orderBy: {
        fieldName: orderFieldName,
        direction: orderDirection
      },
      filters
    }
  }, [location])

  const changeParams = ({ pagination, filters, sorter }) => {
    const newParams = {
      ...unWrapValues({ ...params.filters, ...filters }),
      pageNumber: pagination.current || 1,
      pageSize: pagination.pageSize || 10,
      orderFieldName: sorter?.column?.key,
      orderDirection: sorter?.column?.key && (
        sorter.order === 'ascend' ? ListSortDirection.Ascending : ListSortDirection.Descending
      )
    }

    history.replace({
      pathname: location.pathname,
      search: stringify(newParams, { encode: false, arrayFormat: 'comma' })
    })
  }

  const paramsToApi = useMemo(() => {
    return {
      ...unWrapValues(params.filters),
      ...unWrapValues(params.pagination, 'pagination'),
      ...unWrapValues(params.orderBy, 'orderBy')
    }
  }, [params])

  return { changeParams, params, paramsToApi }
}

export default useTableControl
