import { createSlice } from '@reduxjs/toolkit'
import { configurationApi } from 'services/api'

const initialState = {
  configuration: {},
  isConfigurationLoading: false,
  error: null
}

const configurationSlice = createSlice({
  name: 'configuration',
  initialState,
  reducers: {
    getConfigurationApiStart (state) {
      state.error = null
      state.isConfigurationLoading = true
    },
    getConfigurationSuccess (state, { payload }) {
      state.configuration = payload
      state.error = null
      state.isConfigurationLoading = false
    },
    getConfigurationFailed (state, { payload }) {
      state.configuration = {}
      state.error = payload.error
      state.isConfigurationLoading = false
    },
    clear (state) {
      state.configuration = {}
      state.error = null
    }
  }
})

const {
  getConfigurationApiStart,
  getConfigurationSuccess,
  getConfigurationFailed,
  clear
} = configurationSlice.actions

const getConfiguration = () => async dispatch => {
  try {
    dispatch(getConfigurationApiStart())
    const configuration = await configurationApi.apiConfigurationGet()
    dispatch(getConfigurationSuccess(configuration))
  } catch (error) {
    dispatch(getConfigurationFailed({ error }))
  }
}

export {
  getConfiguration,
  clear
}

export default configurationSlice.reducer
