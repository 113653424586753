import { createSlice } from '@reduxjs/toolkit'
import { groupApi, powerPlantApi } from 'services/api'
import { ModalStatus } from 'models/enum'

const initialState = {
  availablePowerPlantList: [],
  powerPlantGroupList: [],
  powerPlantGroup: {},
  powerPlantGroupModalStatus: ModalStatus.Hidden,
  isListLoading: false,
  isEntityLoading: false,
  error: null
}

const powerPlantGroupSlice = createSlice({
  name: 'powerplantgroups',
  initialState,
  reducers: {
    getAvailablePowerPlantListSuccess (state, { payload }) {
      state.availablePowerPlantList = payload
      state.error = null
    },
    getAvailablePowerPlantListFailed (state, { payload }) {
      state.availablePowerPlantList = []
      state.error = payload.error
    },
    getPowerPlantGroupListStart (state) {
      state.error = null
      state.isListLoading = true
    },
    getPowerPlantGroupListSuccess (state, { payload }) {
      state.powerPlantGroupList = payload
      state.error = null
      state.isListLoading = false
    },
    getPowerPlantGroupListFailed (state, { payload }) {
      state.powerPlantGroupList = []
      state.error = payload.error
      state.isListLoading = false
    },
    getPowerPlantGroupSuccess (state, { payload }) {
      state.powerPlantGroup = payload
      state.error = null
      state.isEntityLoading = false
    },
    getPowerPlantGroupFailed (state, { payload }) {
      state.powerPlantGroup = {}
      state.error = payload.error
      state.isEntityLoading = false
    },
    createPowerPlantGroupSuccess (state) {
      state.powerPlantGroup = {}
      state.error = null
      state.powerPlantGroupModalStatus = ModalStatus.Hidden
      state.isEntityLoading = false
    },
    createPowerPlantGroupFailed (state, { payload }) {
      state.error = payload.error
      state.isEntityLoading = false
    },
    editPowerPlantGroupSuccess (state) {
      state.powerPlantGroup = {}
      state.error = null
      state.powerPlantGroupModalStatus = ModalStatus.Hidden
      state.isEntityLoading = false
    },
    editPowerPlantGroupFailed (state, { payload }) {
      state.error = payload.error
      state.isEntityLoading = false
    },
    deletePowerPlantGroupSuccess (state) {
      state.error = null
    },
    deletePowerPlantGroupFailed (state, { payload }) {
      state.error = payload.error
    },
    setPowerPlantGroupModalStatus (state, { payload }) {
      state.powerPlantGroupModalStatus = payload
      if (payload === ModalStatus.Hidden) {
        state.powerPlantGroup = {}
      }
    },
    setPowerPlantGroupLoading (state) {
      state.isEntityLoading = true
    },
    clear (state) {
      state.powerPlantGroupList = []
      state.availablePowerPlantList = []
      state.powerPlantGroup = {}
      state.powerPlantGroupModalStatus = ModalStatus.Hidden
      state.error = null
    }
  }
})

const {
  getAvailablePowerPlantListSuccess,
  getAvailablePowerPlantListFailed,
  getPowerPlantGroupListStart,
  getPowerPlantGroupListSuccess,
  getPowerPlantGroupListFailed,
  getPowerPlantGroupSuccess,
  getPowerPlantGroupFailed,
  createPowerPlantGroupSuccess,
  createPowerPlantGroupFailed,
  editPowerPlantGroupSuccess,
  editPowerPlantGroupFailed,
  deletePowerPlantGroupSuccess,
  deletePowerPlantGroupFailed,
  setPowerPlantGroupModalStatus,
  setPowerPlantGroupLoading,
  clear
} = powerPlantGroupSlice.actions

const getAvailablePowerPlantList = () => async dispatch => {
  try {
    const availablePowerPlantList = await powerPlantApi.getAllSelectablePowerPlants()
    dispatch(getAvailablePowerPlantListSuccess(availablePowerPlantList))
  } catch (error) {
    dispatch(getAvailablePowerPlantListFailed({ error }))
  }
}

const getPowerPlantGroupList = () => async dispatch => {
  try {
    dispatch(getPowerPlantGroupListStart())
    const powerPlantGroupList = await groupApi.getAllPowerPlantGroup()
    dispatch(getPowerPlantGroupListSuccess(powerPlantGroupList))
  } catch (error) {
    dispatch(getPowerPlantGroupListFailed({ error }))
  }
}

const getPowerPlantGroup = (id) => async dispatch => {
  try {
    dispatch(setPowerPlantGroupLoading())
    const powerPlantGroup = await groupApi.getPowerPlantGroup({ id })
    const sortedRelatedPowerPlants = { ...powerPlantGroup, relatedPowerPlants: (powerPlantGroup.relatedPowerPlants ?? []).sort((a, b) => a.name.localeCompare(b.name)) }
    dispatch(getPowerPlantGroupSuccess(sortedRelatedPowerPlants))
  } catch (error) {
    dispatch(getPowerPlantGroupFailed({ error }))
  }
}

const createPowerPlantGroup = (createPowerPlantGroupDto) => async dispatch => {
  try {
    dispatch(setPowerPlantGroupLoading())
    await groupApi.createPowerPlantGroup({ createPowerPlantGroupDto })
    dispatch(createPowerPlantGroupSuccess())
    dispatch(getPowerPlantGroupList())
  } catch (error) {
    dispatch(createPowerPlantGroupFailed({ error }))
  }
}

const editPowerPlantGroup = (id, updatePowerPlantGroupDto) => async dispatch => {
  try {
    dispatch(setPowerPlantGroupLoading())
    await groupApi.editPowerPlantGroup({ id, updatePowerPlantGroupDto })
    dispatch(editPowerPlantGroupSuccess())
    dispatch(getPowerPlantGroupList())
  } catch (error) {
    dispatch(editPowerPlantGroupFailed({ error }))
  }
}

const deletePowerPlantGroup = (id) => async dispatch => {
  try {
    await groupApi.deletePowerPlantGroup({ id })
    dispatch(deletePowerPlantGroupSuccess())
    dispatch(getPowerPlantGroupList())
  } catch (error) {
    dispatch(deletePowerPlantGroupFailed({ error }))
  }
}

const checkPowerPlantGroupNameUniqueness = (name) => async dispatch => {
  try {
    const powerPlantGroupList = await groupApi.getAllPowerPlantGroup({ name })
    return powerPlantGroupList.length
  } catch (error) {
    return 0
  }
}

const checkEicUniqueness = (eic) => async dispatch => {
  try {
    const powerPlantGroupList = await groupApi.getAllPowerPlantGroup({ eic })
    return powerPlantGroupList.length
  } catch (error) {
    return 0
  }
}

export {
  getAvailablePowerPlantList,
  getPowerPlantGroupList,
  getPowerPlantGroup,
  createPowerPlantGroup,
  editPowerPlantGroup,
  deletePowerPlantGroup,
  setPowerPlantGroupModalStatus,
  checkPowerPlantGroupNameUniqueness,
  checkEicUniqueness,
  clear
}

export default powerPlantGroupSlice.reducer
