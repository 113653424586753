import { createSlice } from '@reduxjs/toolkit'
import {
  predictionReportageApi,
  scheduleDataCorrectionOptionApi,
  scheduleMetadataApi
} from 'services/api'
import { ModalStatus } from 'models/enum'

const initialState = {
  scheduleManagementList: {},
  scheduleData: {},
  nextPossibleSchedulingDateTime: null,
  isListLoading: false,
  isEntityLoading: false,
  isFlagLoading: false,
  isNextPossibleSchedulingDateTimeLoading: false,
  scheduleModalStatus: ModalStatus.Hidden,
  selectedScheduleManagementId: null,
  error: null,
  isDayAheadScheduleSaveLoading: false,
  isIntraDayScheduleSaveLoading: false,
  scheduleDataCorrectionOption: {},
  hasDirtyFields: false
}

const scheduleManagementSlice = createSlice({
  name: 'schedulemanagement',
  initialState,
  reducers: {
    getScheduleManagementListStart (state) {
      state.error = null
      state.isListLoading = true
    },
    getScheduleManagementListSuccess (state, { payload }) {
      state.scheduleManagementList = payload
      state.error = null
      state.isListLoading = false
    },
    getScheduleManagementListFailed (state, { payload }) {
      state.scheduleManagementList = {}
      state.error = payload.error
      state.isListLoading = false
    },
    getScheduleDataStart (state) {
      state.isEntityLoading = true
    },
    getScheduleDataSuccess (state, { payload }) {
      state.isEntityLoading = false
      state.scheduleData = payload
    },
    getScheduleDataFailed (state, { payload }) {
      state.isEntityLoading = false
      state.error = payload.error
    },
    createScheduleStart (state) {
      state.isEntityLoading = true
    },
    createScheduleSuccess (state) {
      state.isEntityLoading = false
      state.scheduleModalStatus = ModalStatus.Hidden
      state.scheduleData = {}
    },
    createScheduleFailed (state, { payload }) {
      state.isEntityLoading = false
      state.error = payload.error
    },
    downloadScheduleManagementXMLSuccess (state) {
      state.error = null
    },
    downloadScheduleManagementXMLFailed (state, { payload }) {
      state.error = payload
    },
    nextPossibleSchedulingDateTimeStart (state) {
      state.isNextPossibleSchedulingDateTimeLoading = true
    },
    nextPossibleSchedulingDateTimeSuccess (state, { payload }) {
      state.nextPossibleSchedulingDateTime = payload
      state.isNextPossibleSchedulingDateTimeLoading = false
    },
    nextPossibleSchedulingDateTimeFailed (state, { payload }) {
      state.isNextPossibleSchedulingDateTimeLoading = false
      state.error = payload
    },
    flagReportageToMEKStart (state) {
      state.isFlagLoading = true
    },
    flagReportageToMEKSuccess (state) {
      state.isFlagLoading = false
    },
    flagReportageToMEKFailed (state, { payload }) {
      state.isFlagLoading = false
      state.error = payload
    },
    modifyScheduleCell (state, { payload }) {
      state.scheduleData = payload
    },
    setScheduleModalStatus (state, { payload }) {
      state.scheduleModalStatus = payload
      if (payload === ModalStatus.Hidden) {
        state.scheduleData = {}
      }
    },
    setSelectedScheduleManagementId (state, { payload }) {
      state.selectedScheduleManagementId = payload
    },
    dayAheadScheduleSaveStart (state) {
      state.isDayAheadScheduleSaveLoading = true
    },
    dayAheadScheduleSaveSuccess (state) {
      state.isDayAheadScheduleSaveLoading = false
    },
    dayAheadScheduleSaveFailed (state, { payload }) {
      state.isDayAheadScheduleSaveLoading = false
      state.error = payload.error
    },
    intraDayScheduleSaveStart (state) {
      state.isIntraDayScheduleSaveLoading = true
    },
    intraDayScheduleSaveSuccess (state) {
      state.isIntraDayScheduleSaveLoading = false
    },
    intraDayScheduleSaveFailed (state, { payload }) {
      state.isIntraDayScheduleSaveLoading = false
      state.error = payload.error
    },
    getScheduleDataCorrectionOptionStart (state) {
      state.isEntityLoading = true
    },
    getScheduleDataCorrectionOptionSuccess (state, { payload }) {
      state.isEntityLoading = false
      state.scheduleDataCorrectionOption = payload
    },
    getScheduleDataCorrectionOptionFailed (state, { payload }) {
      state.isEntityLoading = false
      state.error = payload.error
    },
    setDirtyFields (state, { payload }) {
      state.hasDirtyFields = payload
    },
    clear (state) {
      state.scheduleManagementList = {}
      state.scheduleData = {}
      state.scheduleDataCorrectionOption = {}
      state.selectedScheduleManagementId = null
      state.scheduleModalStatus = ModalStatus.Hidden
      state.error = null
    }
  }
})

const {
  getScheduleManagementListStart,
  getScheduleManagementListSuccess,
  getScheduleManagementListFailed,
  getScheduleDataStart,
  getScheduleDataSuccess,
  getScheduleDataFailed,
  createScheduleStart,
  createScheduleSuccess,
  createScheduleFailed,
  downloadScheduleManagementXMLSuccess,
  downloadScheduleManagementXMLFailed,
  nextPossibleSchedulingDateTimeStart,
  nextPossibleSchedulingDateTimeSuccess,
  nextPossibleSchedulingDateTimeFailed,
  flagReportageToMEKStart,
  flagReportageToMEKSuccess,
  flagReportageToMEKFailed,
  modifyScheduleCell,
  setScheduleModalStatus,
  setSelectedScheduleManagementId,
  dayAheadScheduleSaveStart,
  dayAheadScheduleSaveSuccess,
  dayAheadScheduleSaveFailed,
  intraDayScheduleSaveStart,
  intraDayScheduleSaveSuccess,
  intraDayScheduleSaveFailed,
  getScheduleDataCorrectionOptionStart,
  getScheduleDataCorrectionOptionSuccess,
  getScheduleDataCorrectionOptionFailed,
  setDirtyFields,
  clear
} = scheduleManagementSlice.actions

const getScheduleManagementList = (params) => async (dispatch) => {
  try {
    dispatch(getScheduleManagementListStart())
    const scheduleManagementList = await scheduleMetadataApi.getPaginatedCategories(
      params
    )
    dispatch(getScheduleManagementListSuccess(scheduleManagementList))
  } catch (error) {
    dispatch(getScheduleManagementListFailed({ error }))
  }
}

const getScheduleData = (id) => async (dispatch) => {
  try {
    dispatch(getScheduleDataStart())
    const scheduleData = await scheduleMetadataApi.createCalculatedScheduleData(
      { id }
    )
    dispatch(getScheduleDataSuccess(scheduleData))
  } catch (error) {
    dispatch(getScheduleDataFailed({ error }))
  }
}

const getCalculatedSchedulaData = (id, filter) => async (dispatch) => {
  try {
    dispatch(getScheduleDataStart())
    const scheduleData = await scheduleMetadataApi.createCalculatedScheduleData(
      {
        id,
        scheduleMetadataCalculatorDto: { filter }
      }
    )
    dispatch(getScheduleDataSuccess(scheduleData))
  } catch (error) {
    dispatch(getScheduleDataFailed({ error }))
  }
}

const createSchedule = (
  createScheduleMetadataScheduleDataDto,
  params
) => async (dispatch) => {
  try {
    const { calculateResult } = createScheduleMetadataScheduleDataDto

    dispatch(createScheduleStart())
    await scheduleMetadataApi.createUpdatedSchedule({
      createScheduleMetadataScheduleDataDto: {
        id: calculateResult.id,
        scheduleData: calculateResult.scheduleData
      }
    })
    await dispatch(getScheduleManagementList(params))
    dispatch(createScheduleSuccess())
  } catch (error) {
    dispatch(createScheduleFailed({ error }))
  }
}

const modifySchedule = (id, modifiedValue) => async (dispatch, getState) => {
  const { scheduleData } = getState().schedulemanagement
  const { key, value } = modifiedValue
  const modifiedScheduleData = scheduleData.calculateResult.scheduleData?.map((item) => ({
    ...item,
    ...(item.id === id && { [key]: value || 0 })
  }))

  dispatch(
    modifyScheduleCell({
      ...scheduleData,
      calculateResult: {
        ...scheduleData.calculateResult,
        scheduleData: modifiedScheduleData
      }
    })
  )
}

const downloadScheduleManagementXML = (id) => async (dispatch) => {
  try {
    const response = await scheduleMetadataApi.scheduleMetadataXml(
      { id },
      { responseType: 'blob' }
    )
    dispatch(downloadScheduleManagementXMLSuccess())
    return response
  } catch (error) {
    dispatch(downloadScheduleManagementXMLFailed({ error }))
    return null
  }
}

const flagReportageToMEK = (params, id, status) => async (dispatch) => {
  try {
    dispatch(flagReportageToMEKStart())
    await scheduleMetadataApi.updateSubmission({ id, body: status })
    await dispatch(getScheduleManagementList(params))
    dispatch(flagReportageToMEKSuccess())
  } catch (error) {
    dispatch(flagReportageToMEKFailed({ error }))
  }
}

const getNextPossibleSchedulingDateTime = () => async (dispatch) => {
  try {
    dispatch(nextPossibleSchedulingDateTimeStart())
    const nextSchedulingDateTime = await predictionReportageApi.getNextPossibleSchedulingDateTime()
    dispatch(nextPossibleSchedulingDateTimeSuccess(nextSchedulingDateTime))
  } catch (error) {
    dispatch(nextPossibleSchedulingDateTimeFailed({ error }))
  }
}

const updateDayAheadSchedules = (scheduleMetadataCalculatorFilterDto) => async (
  dispatch
) => {
  try {
    dispatch(dayAheadScheduleSaveStart())
    await scheduleDataCorrectionOptionApi.editScheduleDataCorrectionOption({
      scheduleMetadataCalculatorFilterDto
    })
    dispatch(dayAheadScheduleSaveSuccess())
  } catch (error) {
    dispatch(dayAheadScheduleSaveFailed({ error }))
    throw error
  }
}

const updateIntraDaySchedules = (scheduleMetadataCalculatorFilterDto) => async (
  dispatch
) => {
  try {
    dispatch(intraDayScheduleSaveStart())
    await scheduleDataCorrectionOptionApi.editIdScheduleDataCorrectionOption({
      scheduleMetadataCalculatorFilterDto
    })
    dispatch(intraDayScheduleSaveSuccess())
  } catch (error) {
    dispatch(intraDayScheduleSaveFailed({ error }))
    throw error
  }
}

const getDayAheadAndIntraDaySchedules = () => async (dispatch) => {
  try {
    dispatch(getScheduleDataCorrectionOptionStart())
    const scheduleDataCorrectionOption = await scheduleDataCorrectionOptionApi.getScheduleDataCorrectionOption()
    dispatch(getScheduleDataCorrectionOptionSuccess(scheduleDataCorrectionOption))
  } catch (error) {
    dispatch(getScheduleDataCorrectionOptionFailed({ error }))
  }
}

export {
  getScheduleManagementList,
  downloadScheduleManagementXML,
  flagReportageToMEK,
  getScheduleData,
  getCalculatedSchedulaData,
  getNextPossibleSchedulingDateTime,
  createSchedule,
  modifySchedule,
  setScheduleModalStatus,
  setSelectedScheduleManagementId,
  updateDayAheadSchedules,
  updateIntraDaySchedules,
  getDayAheadAndIntraDaySchedules,
  setDirtyFields,
  clear
}

export default scheduleManagementSlice.reducer
