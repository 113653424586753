import Cookies from 'js-cookie'
import { createSlice } from '@reduxjs/toolkit'
import { history } from 'router'
import { authenticationApi, removeApiCredentials, userApi } from 'services/api'
import { COOKIE_PREFIX, getInfoFromToken } from 'utils/token'
import { Role } from 'models/enum'
import { urlWithQuery } from 'utils/url'
import { getTomorrowUTC } from 'utils/date'

export const tokenKey = `${COOKIE_PREFIX}-token`
export const refreshTokenKey = `${COOKIE_PREFIX}-refreshtoken`

const initialState = {
  isLoading: false,
  isLoggedIn: false,
  isLoginModalVisible: false,
  userId: null,
  username: null,
  profileId: null,
  role: null,
  expired: false,
  error: null,
  isPasswordChangeLoading: false,
  sessionCompanyProfileId: null
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginStart (state) {
      state.isLoading = true
    },
    loginSuccess (state, { payload }) {
      state.isLoading = false
      state.isLoggedIn = true
      state.isLoginModalVisible = false
      state.userId = payload.userId
      state.username = payload.username
      state.role = payload.role
      state.profileId = payload.profileId
      state.error = null
      state.expired = false
    },
    loginFailed (state, { payload }) {
      state.isLoading = false
      state.isLoggedIn = false
      state.error = payload.error
    },
    setLoginModalVisible (state, { payload }) {
      state.isLoginModalVisible = payload
    },
    updateStarted (state) {
      state.isUpdating = true
    },
    updateSuccess (state, { payload }) {
      state.isLoggedIn = true
      state.userId = payload.userId
      state.username = payload.username
      state.role = payload.role
      state.profileId = payload.profileId
      state.isUpdating = false
    },
    logoutRequest (state, { payload }) {
      state.isLoading = false
      state.isLoggedIn = false
      state.userId = null
      state.username = null
      state.role = null
      state.error = null
      state.profileId = null
      state.sessionCompanyProfileId = null
      state.isUpdating = false
      if (payload) {
        state.expired = true
      }
    },
    changePasswordStart (state) {
      state.isPasswordChangeLoading = true
    },
    changePasswordSuccess (state) {
      state.isPasswordChangeLoading = false
    },
    changePasswordFailed (state, { payload }) {
      state.isPasswordChangeLoading = false
      state.error = payload.error
    },
    getSessionCompanyProfileIdSuccess (state, { payload }) {
      state.sessionCompanyProfileId = payload
      state.error = null
    },
    getSessionCompanyProfileIdFailed (state, { payload }) {
      state.sessionCompanyProfileId = null
      state.error = payload.error
    },
    updateSessionCompanyProfileId (state, { payload }) {
      state.sessionCompanyProfileId = payload
    }
  }
})

const {
  loginStart,
  loginSuccess,
  loginFailed,
  setLoginModalVisible,
  updateStarted,
  updateSuccess,
  logoutRequest,
  changePasswordStart,
  changePasswordSuccess,
  changePasswordFailed,
  getSessionCompanyProfileIdSuccess,
  getSessionCompanyProfileIdFailed,
  updateSessionCompanyProfileId
} = authSlice.actions

const login = (loginRequestDto) => async dispatch => {
  try {
    dispatch(loginStart())
    const { accessToken, refreshToken } = await authenticationApi.apiAuthenticationLoginPost({ loginRequestDto })

    const { expires, ...userInfo } = getInfoFromToken(accessToken)
    Cookies.set(tokenKey, accessToken, { expires, sameSite: 'strict' })
    Cookies.set(refreshTokenKey, refreshToken, { expires, sameSite: 'strict' })

    dispatch(loginSuccess(userInfo))
    if (userInfo.role === Role.Office) {
      history.push(urlWithQuery('/reportage/analisys', {
        deliveryDate: getTomorrowUTC()
      }))
    } else if (userInfo.role === Role.SysAdmin) {
      await dispatch(getSessionCompanyProfileId(userInfo.userId))
      history.push(`/profile/edit/${userInfo.profileId}`)
    } else {
      history.push('/data/masterdata/balance-responsible')
    }
  } catch (error) {
    dispatch(loginFailed({ error }))
  }
}

const update = () => async dispatch => {
  dispatch(updateStarted())
  const accessToken = Cookies.get(tokenKey)
  if (accessToken) {
    const userInfo = getInfoFromToken(accessToken)
    if (userInfo.role === Role.SysAdmin) {
      await dispatch(getSessionCompanyProfileId(userInfo.userId))
    }
    dispatch(updateSuccess(userInfo))
  } else {
    dispatch(logoutRequest())
  }
}

const logout = (expired) => async (dispatch) => {
  try {
    await authenticationApi.apiAuthenticationLogoutPost()
  } catch {
    console.warn('Token expired.')
  } finally {
    dispatch(logoutRequest(expired))
    Cookies.remove(tokenKey)
    Cookies.remove(refreshTokenKey)
    removeApiCredentials()
    history.push('/')
  }
}

const refreshToken = () => async dispatch => {
  const oldRefreshToken = Cookies.get(refreshTokenKey)
  const oldAccessToken = Cookies.get(tokenKey)
  const { accessToken, refreshToken } = await authenticationApi.apiAuthenticationRefreshPost({
    refreshDto: {
      refreshToken: oldRefreshToken,
      accessToken: oldAccessToken
    }
  })

  const { expires, ...userInfo } = getInfoFromToken(accessToken)
  Cookies.set(tokenKey, accessToken, { expires, sameSite: 'strict' })
  refreshToken && Cookies.set(refreshTokenKey, refreshToken, { expires, sameSite: 'strict' })
  dispatch(loginSuccess(userInfo))
  return accessToken
}

const changePassword = (userId, changePasswordDto) => async dispatch => {
  try {
    dispatch(changePasswordStart())
    await authenticationApi.changePassword({ userId, changePasswordDto })
    dispatch(changePasswordSuccess())
    return { isSuccess: true }
  } catch (error) {
    dispatch(changePasswordFailed({ error }))
    return { isSuccess: false }
  }
}

const getSessionCompanyProfileId = (id) => async dispatch => {
  try {
    const user = await userApi.getUser({ id })
    dispatch(getSessionCompanyProfileIdSuccess(user.sessionCompanyProfileId))
  } catch (error) {
    dispatch(getSessionCompanyProfileIdFailed({ error }))
  }
}

export {
  login,
  update,
  logout,
  setLoginModalVisible,
  logoutRequest,
  refreshToken,
  changePassword,
  getSessionCompanyProfileId,
  updateSessionCompanyProfileId
}

export default authSlice.reducer
