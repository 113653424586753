import i18n from 'i18n'

export const minMaxValidator = (min, max) => ({ value }) => {
  if (isNaN(value) || value === null || value < min || value > max) {
    return {
      isValid: false,
      message: i18n.t('efficiencyCurve.min-max-validation', { min, max })
    }
  }
  return {
    isValid: true
  }
}

export const onlyPositiveNumberValidator = () => ({ value }) => {
  if (isNaN(value) || value < 0) {
    return {
      isValid: false,
      message: i18n.t('validation.form-messages.number.only-positive')
    }
  }
  return {
    isValid: true
  }
}

export const exclusiveMaximum = (max) => ({
  validator (rule, value) {
    if (value === max) {
      return Promise.reject(i18n.t('validation.form-messages.number.exclusive-maximum', { max }))
    }
    return Promise.resolve()
  }
})

export const exclusiveMinimum = (min) => ({
  validator (rule, value) {
    if (value === min) {
      return Promise.reject(i18n.t('validation.form-messages.number.exclusive-minimum', { min }))
    }
    return Promise.resolve()
  }
})
