"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ScheduleApi = exports.ScheduleApiFactory = exports.ScheduleApiFp = exports.ScheduleApiAxiosParamCreator = void 0;
var globalImportUrl = require("url");
var axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
var base_1 = require("../base");
/**
 * ScheduleApi - axios parameter creator
 * @export
 */
exports.ScheduleApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @param {number} [powerPlantId]
         * @param {boolean} [activeOnly]
         * @param {boolean} [successfulOnly]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDeliveryDate: function (powerPlantId, activeOnly, successfulOnly, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, accessToken, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/api/Schedule/GetAllDeliveryDate";
                    localVarUrlObj = globalImportUrl.parse(localVarPath, true);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    // authentication bearer required
                    // http bearer authentication required
                    if (configuration && configuration.accessToken) {
                        accessToken = typeof configuration.accessToken === 'function'
                            ? configuration.accessToken()
                            : configuration.accessToken;
                        localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
                    }
                    if (powerPlantId !== undefined) {
                        localVarQueryParameter['powerPlantId'] = powerPlantId;
                    }
                    if (activeOnly !== undefined) {
                        localVarQueryParameter['activeOnly'] = activeOnly;
                    }
                    if (successfulOnly !== undefined) {
                        localVarQueryParameter['successfulOnly'] = successfulOnly;
                    }
                    localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
                    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
                    delete localVarUrlObj.search;
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: globalImportUrl.format(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {number} [powerPlantId]
         * @param {string} [deliveryDate]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSchedule: function (powerPlantId, deliveryDate, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, accessToken, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/api/Schedule";
                    localVarUrlObj = globalImportUrl.parse(localVarPath, true);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    // authentication bearer required
                    // http bearer authentication required
                    if (configuration && configuration.accessToken) {
                        accessToken = typeof configuration.accessToken === 'function'
                            ? configuration.accessToken()
                            : configuration.accessToken;
                        localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
                    }
                    if (powerPlantId !== undefined) {
                        localVarQueryParameter['powerPlantId'] = powerPlantId;
                    }
                    if (deliveryDate !== undefined) {
                        localVarQueryParameter['deliveryDate'] = (deliveryDate instanceof Date) ?
                            deliveryDate.toISOString() :
                            deliveryDate;
                    }
                    localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
                    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
                    delete localVarUrlObj.search;
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: globalImportUrl.format(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {number} [powerPlantId]
         * @param {string} [deliveryDate]
         * @param {string} [predictionDateTime]
         * @param {ListSortDirection} [orderByDirection]
         * @param {string} [orderByFieldName]
         * @param {number} [paginationPageNumber]
         * @param {number} [paginationPageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaginatedSchedule: function (powerPlantId, deliveryDate, predictionDateTime, orderByDirection, orderByFieldName, paginationPageNumber, paginationPageSize, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, accessToken, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/api/Schedule/paginated";
                    localVarUrlObj = globalImportUrl.parse(localVarPath, true);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    // authentication bearer required
                    // http bearer authentication required
                    if (configuration && configuration.accessToken) {
                        accessToken = typeof configuration.accessToken === 'function'
                            ? configuration.accessToken()
                            : configuration.accessToken;
                        localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
                    }
                    if (powerPlantId !== undefined) {
                        localVarQueryParameter['PowerPlantId'] = powerPlantId;
                    }
                    if (deliveryDate !== undefined) {
                        localVarQueryParameter['DeliveryDate'] = (deliveryDate instanceof Date) ?
                            deliveryDate.toISOString() :
                            deliveryDate;
                    }
                    if (predictionDateTime !== undefined) {
                        localVarQueryParameter['PredictionDateTime'] = (predictionDateTime instanceof Date) ?
                            predictionDateTime.toISOString() :
                            predictionDateTime;
                    }
                    if (orderByDirection !== undefined) {
                        localVarQueryParameter['OrderBy.Direction'] = orderByDirection;
                    }
                    if (orderByFieldName !== undefined) {
                        localVarQueryParameter['OrderBy.FieldName'] = orderByFieldName;
                    }
                    if (paginationPageNumber !== undefined) {
                        localVarQueryParameter['Pagination.PageNumber'] = paginationPageNumber;
                    }
                    if (paginationPageSize !== undefined) {
                        localVarQueryParameter['Pagination.PageSize'] = paginationPageSize;
                    }
                    localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
                    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
                    delete localVarUrlObj.search;
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: globalImportUrl.format(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
    };
};
/**
 * ScheduleApi - functional programming interface
 * @export
 */
exports.ScheduleApiFp = function (configuration) {
    return {
        /**
         *
         * @param {number} [powerPlantId]
         * @param {boolean} [activeOnly]
         * @param {boolean} [successfulOnly]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDeliveryDate: function (powerPlantId, activeOnly, successfulOnly, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, exports.ScheduleApiAxiosParamCreator(configuration).getAllDeliveryDate(powerPlantId, activeOnly, successfulOnly, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {number} [powerPlantId]
         * @param {string} [deliveryDate]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSchedule: function (powerPlantId, deliveryDate, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, exports.ScheduleApiAxiosParamCreator(configuration).getAllSchedule(powerPlantId, deliveryDate, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {number} [powerPlantId]
         * @param {string} [deliveryDate]
         * @param {string} [predictionDateTime]
         * @param {ListSortDirection} [orderByDirection]
         * @param {string} [orderByFieldName]
         * @param {number} [paginationPageNumber]
         * @param {number} [paginationPageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaginatedSchedule: function (powerPlantId, deliveryDate, predictionDateTime, orderByDirection, orderByFieldName, paginationPageNumber, paginationPageSize, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, exports.ScheduleApiAxiosParamCreator(configuration).getPaginatedSchedule(powerPlantId, deliveryDate, predictionDateTime, orderByDirection, orderByFieldName, paginationPageNumber, paginationPageSize, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
    };
};
/**
 * ScheduleApi - factory interface
 * @export
 */
exports.ScheduleApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @param {number} [powerPlantId]
         * @param {boolean} [activeOnly]
         * @param {boolean} [successfulOnly]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDeliveryDate: function (powerPlantId, activeOnly, successfulOnly, options) {
            return exports.ScheduleApiFp(configuration).getAllDeliveryDate(powerPlantId, activeOnly, successfulOnly, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {number} [powerPlantId]
         * @param {string} [deliveryDate]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSchedule: function (powerPlantId, deliveryDate, options) {
            return exports.ScheduleApiFp(configuration).getAllSchedule(powerPlantId, deliveryDate, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {number} [powerPlantId]
         * @param {string} [deliveryDate]
         * @param {string} [predictionDateTime]
         * @param {ListSortDirection} [orderByDirection]
         * @param {string} [orderByFieldName]
         * @param {number} [paginationPageNumber]
         * @param {number} [paginationPageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaginatedSchedule: function (powerPlantId, deliveryDate, predictionDateTime, orderByDirection, orderByFieldName, paginationPageNumber, paginationPageSize, options) {
            return exports.ScheduleApiFp(configuration).getPaginatedSchedule(powerPlantId, deliveryDate, predictionDateTime, orderByDirection, orderByFieldName, paginationPageNumber, paginationPageSize, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
/**
 * ScheduleApi - object-oriented interface
 * @export
 * @class ScheduleApi
 * @extends {BaseAPI}
 */
var ScheduleApi = /** @class */ (function (_super) {
    __extends(ScheduleApi, _super);
    function ScheduleApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @param {ScheduleApiGetAllDeliveryDateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScheduleApi
     */
    ScheduleApi.prototype.getAllDeliveryDate = function (requestParameters, options) {
        var _this = this;
        if (requestParameters === void 0) { requestParameters = {}; }
        return exports.ScheduleApiFp(this.configuration).getAllDeliveryDate(requestParameters.powerPlantId, requestParameters.activeOnly, requestParameters.successfulOnly, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {ScheduleApiGetAllScheduleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScheduleApi
     */
    ScheduleApi.prototype.getAllSchedule = function (requestParameters, options) {
        var _this = this;
        if (requestParameters === void 0) { requestParameters = {}; }
        return exports.ScheduleApiFp(this.configuration).getAllSchedule(requestParameters.powerPlantId, requestParameters.deliveryDate, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {ScheduleApiGetPaginatedScheduleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScheduleApi
     */
    ScheduleApi.prototype.getPaginatedSchedule = function (requestParameters, options) {
        var _this = this;
        if (requestParameters === void 0) { requestParameters = {}; }
        return exports.ScheduleApiFp(this.configuration).getPaginatedSchedule(requestParameters.powerPlantId, requestParameters.deliveryDate, requestParameters.predictionDateTime, requestParameters.orderByDirection, requestParameters.orderByFieldName, requestParameters.paginationPageNumber, requestParameters.paginationPageSize, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return ScheduleApi;
}(base_1.BaseAPI));
exports.ScheduleApi = ScheduleApi;
