import { createSlice } from '@reduxjs/toolkit'
import { correctionCoefficientApi } from 'services/api'

const initialState = {
  coefficients: {},
  isCoefficientsLoading: false,
  useCorrectionFactor: null,
  isCorrectionFactorLoading: false,
  error: null
}

const correctionCoefficientSlice = createSlice({
  name: 'correctionCoefficient',
  initialState,
  reducers: {
    getCoefficientsApiStart (state) {
      state.error = null
      state.isCoefficientsLoading = true
    },
    getCoefficientsSuccess (state, { payload }) {
      state.coefficients = payload
      state.error = null
      state.isCoefficientsLoading = false
    },
    getCoefficientsFailed (state, { payload }) {
      state.coefficients = {}
      state.error = payload.error
      state.isCoefficientsLoading = false
    },
    createCoefficientsSuccess (state) {
      state.coefficients = {}
      state.error = null
      state.isCoefficientsLoading = false
    },
    createCoefficientsFailed (state, { payload }) {
      state.coefficients = {}
      state.error = payload.error
      state.isCoefficientsLoading = false
    },
    getUseCorrectionFactorApiStart (state) {
      state.error = null
      state.isCorrectionFactorLoading = true
    },
    getUseCorrectionFactorSuccess (state, { payload }) {
      state.useCorrectionFactor = payload
      state.error = null
      state.isCorrectionFactorLoading = false
    },
    getUseCorrectionFactorFailed (state, { payload }) {
      state.useCorrectionFactor = null
      state.error = payload.error
      state.isCorrectionFactorLoading = false
    },
    setUseCorrectionFactorSuccess (state) {
      state.useCorrectionFactor = null
      state.error = null
      state.isCorrectionFactorLoading = false
    },
    setUseCorrectionFactorFailed (state, { payload }) {
      state.useCorrectionFactor = null
      state.error = payload.error
      state.isCorrectionFactorLoading = false
    },
    clear (state) {
      state.coefficients = {}
      state.error = null
    }
  }
})

const {
  getCoefficientsApiStart,
  getCoefficientsSuccess,
  getCoefficientsFailed,
  createCoefficientsSuccess,
  createCoefficientsFailed,
  getUseCorrectionFactorApiStart,
  getUseCorrectionFactorSuccess,
  getUseCorrectionFactorFailed,
  setUseCorrectionFactorSuccess,
  setUseCorrectionFactorFailed,
  clear
} = correctionCoefficientSlice.actions

const getCorrectionCoefficients = () => async dispatch => {
  try {
    dispatch(getCoefficientsApiStart())
    const value = await correctionCoefficientApi.getCoefficients()
    dispatch(getCoefficientsSuccess(value))
  } catch (error) {
    dispatch(getCoefficientsFailed({ error }))
  }
}

const createCoefficients = (createCorrectionCoefficientDto) => async dispatch => {
  try {
    dispatch(getCoefficientsApiStart())
    await correctionCoefficientApi.setCoefficients({ createCorrectionCoefficientDto })
    dispatch(createCoefficientsSuccess())
    dispatch(getCorrectionCoefficients())
  } catch (error) {
    dispatch(createCoefficientsFailed({ error }))
  }
}

const getUseCorrectionFactor = () => async dispatch => {
  try {
    dispatch(getUseCorrectionFactorApiStart())
    const useCorrectionFactor = await correctionCoefficientApi.getUseCorrectionFactor()
    dispatch(getUseCorrectionFactorSuccess(useCorrectionFactor))
  } catch (error) {
    dispatch(getUseCorrectionFactorFailed({ error }))
  }
}

const setUseCorrectionFactor = (value) => async dispatch => {
  try {
    dispatch(getUseCorrectionFactorApiStart())
    await correctionCoefficientApi.setUseCorrectionFactor({ value })
    dispatch(setUseCorrectionFactorSuccess())
    dispatch(getUseCorrectionFactor())
  } catch (error) {
    dispatch(setUseCorrectionFactorFailed({ error }))
  }
}

export {
  getCorrectionCoefficients,
  createCoefficients,
  getUseCorrectionFactor,
  setUseCorrectionFactor,
  clear
}

export default correctionCoefficientSlice.reducer
